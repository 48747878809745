.App {
  text-align: center;
}

.App-loader {
  height: 40vmin;
  z-index: 2000;
  margin-top: 2.5%;
}

.oneLine { 
  width: 33%;
  margin-top: 12px;
}


.bkg-glow{
  position: absolute;
  top: 463px;
  width: 280px;
  height: 280px;
  border-radius: 50%;
  background-color: #fff;
  box-shadow: 0 0px 70px -9px #fff;
  z-index: 1000;
}
.App-header {
  background-color: #031929;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo{
  width: 44%;
  position: relative;
  top: 0
}

.bgimg {
  height: 100%;
  background-position: center;
  background-size: cover;
  position: relative;
  color: white;
  font-family: "Courier New", Courier, monospace;
  font-size: 25px;
  text-shadow: 0px 23px 11px black
}

.middle {
  position: absolute;
  transform: translate(-50%, -260%);
  text-align: center;
  z-index: 4000;
}

